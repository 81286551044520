@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'New Amsterdam';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/newamsterdam/v1/YA9Vr02Y5lucHqUlbEe51kBtl7k.ttf) format('truetype');
}
/* 
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
font-family: 'Oswald', sans-serif; 
font-family: 'Kanit', sans-serif; 
font-family: 'Style Script', cursive;
font-family: 'Roboto Condensed', sans-serif;

f15025 - vermillion - call to actions, big color pop
4381c1 - steel blue - sec call to action, small color pop
393d3f - onyx - main background
dedee0 - platinum - card backs
fbfef9 - baby powder - font color

*/
.card-iron {
  background-color: #afac97;
  border: 1px solid #88856f;
  color: white;
}
.card-bronze {
  background-color: #f3cb9e;
  border: 1px solid #c59d6e;
  color: white;
}
.card-silver {
  background-color: #9cadac;
  border: 1px solid #6f7c7b;
  color: white;
}
.card-gold {
  background-color: #ccbf0b;
  border: 1px solid #9b9104;
  color: white;
}
.card-titanium {
  background-color: #25aae1;
  border: 1px solid #436891;
  color: white;
}
.card-uranium {
  background-color: #17ca07;
  border: 1px solid #108d05;
  color: white;
}
/* philli athletics? */
/* st louis browns */
/* Louisville Colonels */
/* kc athletics */
/* Cleveland spiders */
.card {
  /* NL WEST */
}
.card svg {
  /* NL CENTRAL */
  /* NL EAST */
  /* AL WEST */
  /* AL CENTRAL */
  /* AL EAST */
  /* OLD TEAMS */
}
.card svg.lan,
.card svg.LAN,
.card svg.bro,
.card svg.BRO {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.lan .st6,
.card svg.LAN .st6,
.card svg.bro .st6,
.card svg.BRO .st6 {
  fill: #dee0d3;
}
.card svg.lan .st7,
.card svg.LAN .st7,
.card svg.bro .st7,
.card svg.BRO .st7 {
  fill: #005596;
}
.card svg.lan .st8,
.card svg.LAN .st8,
.card svg.bro .st8,
.card svg.BRO .st8 {
  fill: gray;
}
.card svg.lan .st9,
.card svg.LAN .st9,
.card svg.bro .st9,
.card svg.BRO .st9 {
  fill: #1E90FF;
}
.card svg.sfn,
.card svg.SFN,
.card svg.ny1,
.card svg.NY1 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.sfn .st6,
.card svg.SFN .st6,
.card svg.ny1 .st6,
.card svg.NY1 .st6 {
  fill: #dee0d3;
}
.card svg.sfn .st7,
.card svg.SFN .st7,
.card svg.ny1 .st7,
.card svg.NY1 .st7 {
  fill: #F4793E;
}
.card svg.sfn .st8,
.card svg.SFN .st8,
.card svg.ny1 .st8,
.card svg.NY1 .st8 {
  fill: white;
}
.card svg.sfn .st9,
.card svg.SFN .st9,
.card svg.ny1 .st9,
.card svg.NY1 .st9 {
  fill: #231F20;
}
.card svg.col,
.card svg.COL {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.col .st6,
.card svg.COL .st6 {
  fill: #dee0d3;
}
.card svg.col .st7,
.card svg.COL .st7 {
  fill: #220F5D;
}
.card svg.col .st8,
.card svg.COL .st8 {
  fill: #8B8D8B;
}
.card svg.col .st9,
.card svg.COL .st9 {
  fill: black;
}
.card svg.sdn,
.card svg.SDN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.sdn .st6,
.card svg.SDN .st6 {
  fill: #dee0d3;
}
.card svg.sdn .st7,
.card svg.SDN .st7 {
  fill: #2F241D;
}
.card svg.sdn .st8,
.card svg.SDN .st8 {
  fill: white;
}
.card svg.sdn .st9,
.card svg.SDN .st9 {
  fill: #FFC425;
}
.card svg.ari,
.card svg.ARI {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.ari .st6,
.card svg.ARI .st6 {
  fill: #dee0d3;
}
.card svg.ari .st7,
.card svg.ARI .st7 {
  fill: #A71930;
}
.card svg.ari .st8,
.card svg.ARI .st8 {
  fill: #E3D4AD;
}
.card svg.ari .st9,
.card svg.ARI .st9 {
  fill: black;
}
.card svg.chn,
.card svg.CHN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.chn .st6,
.card svg.CHN .st6 {
  fill: #dee0d3;
}
.card svg.chn .st7,
.card svg.CHN .st7 {
  fill: #0E3386;
}
.card svg.chn .st8,
.card svg.CHN .st8 {
  fill: white;
}
.card svg.chn .st9,
.card svg.CHN .st9 {
  fill: #CC3433;
}
.card svg.mil,
.card svg.MIL,
.card svg.ml4,
.card svg.ML4,
.card svg.ML1,
.card svg.ml1,
.card svg.ml2,
.card svg.ML2,
.card svg.ml3,
.card svg.ML3 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.mil .st6,
.card svg.MIL .st6,
.card svg.ml4 .st6,
.card svg.ML4 .st6,
.card svg.ML1 .st6,
.card svg.ml1 .st6,
.card svg.ml2 .st6,
.card svg.ML2 .st6,
.card svg.ml3 .st6,
.card svg.ML3 .st6 {
  fill: #dee0d3;
}
.card svg.mil .st7,
.card svg.MIL .st7,
.card svg.ml4 .st7,
.card svg.ML4 .st7,
.card svg.ML1 .st7,
.card svg.ml1 .st7,
.card svg.ml2 .st7,
.card svg.ML2 .st7,
.card svg.ml3 .st7,
.card svg.ML3 .st7 {
  fill: #12284B;
}
.card svg.mil .st8,
.card svg.MIL .st8,
.card svg.ml4 .st8,
.card svg.ML4 .st8,
.card svg.ML1 .st8,
.card svg.ml1 .st8,
.card svg.ml2 .st8,
.card svg.ML2 .st8,
.card svg.ml3 .st8,
.card svg.ML3 .st8 {
  fill: #003EA0;
}
.card svg.mil .st9,
.card svg.MIL .st9,
.card svg.ml4 .st9,
.card svg.ML4 .st9,
.card svg.ML1 .st9,
.card svg.ml1 .st9,
.card svg.ml2 .st9,
.card svg.ML2 .st9,
.card svg.ml3 .st9,
.card svg.ML3 .st9 {
  fill: #FFC52F;
}
.card svg.sln,
.card svg.SLN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.sln .st6,
.card svg.SLN .st6 {
  fill: #dee0d3;
}
.card svg.sln .st7,
.card svg.SLN .st7 {
  fill: #C41E3A;
}
.card svg.sln .st8,
.card svg.SLN .st8 {
  fill: #22205f;
}
.card svg.sln .st9,
.card svg.SLN .st9 {
  fill: white;
}
.card svg.cin,
.card svg.CIN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.cin .st6,
.card svg.CIN .st6 {
  fill: #dee0d3;
}
.card svg.cin .st7,
.card svg.CIN .st7 {
  fill: #c6011f;
}
.card svg.cin .st8,
.card svg.CIN .st8 {
  fill: black;
}
.card svg.cin .st9,
.card svg.CIN .st9 {
  fill: white;
}
.card svg.pit,
.card svg.PIT {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.pit .st6,
.card svg.PIT .st6 {
  fill: #dee0d3;
}
.card svg.pit .st7,
.card svg.PIT .st7 {
  fill: black;
}
.card svg.pit .st8,
.card svg.PIT .st8 {
  fill: white;
}
.card svg.pit .st9,
.card svg.PIT .st9 {
  fill: #fdb827;
}
.card svg.phi,
.card svg.PHI {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.phi .st6,
.card svg.PHI .st6 {
  fill: #dee0d3;
}
.card svg.phi .st7,
.card svg.PHI .st7 {
  fill: #e81828;
}
.card svg.phi .st8,
.card svg.PHI .st8 {
  fill: #284898;
}
.card svg.phi .st9,
.card svg.PHI .st9 {
  fill: white;
}
.card svg.atl,
.card svg.ATL,
.card svg.bsn,
.card svg.BSN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.atl .st6,
.card svg.ATL .st6,
.card svg.bsn .st6,
.card svg.BSN .st6 {
  fill: #dee0d3;
}
.card svg.atl .st7,
.card svg.ATL .st7,
.card svg.bsn .st7,
.card svg.BSN .st7 {
  fill: #13274f;
}
.card svg.atl .st8,
.card svg.ATL .st8,
.card svg.bsn .st8,
.card svg.BSN .st8 {
  fill: white;
}
.card svg.atl .st9,
.card svg.ATL .st9,
.card svg.bsn .st9,
.card svg.BSN .st9 {
  fill: #ce1141;
}
.card svg.was,
.card svg.WAS,
.card svg.ws2,
.card svg.WS2,
.card svg.ws1,
.card svg.WS1 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.was .st6,
.card svg.WAS .st6,
.card svg.ws2 .st6,
.card svg.WS2 .st6,
.card svg.ws1 .st6,
.card svg.WS1 .st6 {
  fill: #dee0d3;
}
.card svg.was .st7,
.card svg.WAS .st7,
.card svg.ws2 .st7,
.card svg.WS2 .st7,
.card svg.ws1 .st7,
.card svg.WS1 .st7 {
  fill: #ab0003;
}
.card svg.was .st8,
.card svg.WAS .st8,
.card svg.ws2 .st8,
.card svg.WS2 .st8,
.card svg.ws1 .st8,
.card svg.WS1 .st8 {
  fill: white;
}
.card svg.was .st9,
.card svg.WAS .st9,
.card svg.ws2 .st9,
.card svg.WS2 .st9,
.card svg.ws1 .st9,
.card svg.WS1 .st9 {
  fill: #212759;
}
.card svg.mia,
.card svg.MIA,
.card svg.flo,
.card svg.FLO {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.mia .st6,
.card svg.MIA .st6,
.card svg.flo .st6,
.card svg.FLO .st6 {
  fill: #dee0d3;
}
.card svg.mia .st7,
.card svg.MIA .st7,
.card svg.flo .st7,
.card svg.FLO .st7 {
  fill: #00A3E0;
}
.card svg.mia .st8,
.card svg.MIA .st8,
.card svg.flo .st8,
.card svg.FLO .st8 {
  fill: black;
}
.card svg.mia .st9,
.card svg.MIA .st9,
.card svg.flo .st9,
.card svg.FLO .st9 {
  fill: #41748D;
}
.card svg.nyn,
.card svg.NYN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.nyn .st6,
.card svg.NYN .st6 {
  fill: #dee0d3;
}
.card svg.nyn .st7,
.card svg.NYN .st7 {
  fill: #002d72;
}
.card svg.nyn .st8,
.card svg.NYN .st8 {
  fill: white;
}
.card svg.nyn .st9,
.card svg.NYN .st9 {
  fill: #ff5910;
}
.card svg.mon,
.card svg.MON {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.mon .st6,
.card svg.MON .st6 {
  fill: #dee0d3;
}
.card svg.mon .st7,
.card svg.MON .st7 {
  fill: #67ABE5;
}
.card svg.mon .st8,
.card svg.MON .st8 {
  fill: #A1AAAD;
}
.card svg.mon .st9,
.card svg.MON .st9 {
  fill: #E4002B;
}
.card svg.laa,
.card svg.LAA,
.card svg.ana,
.card svg.ANA,
.card svg.cal,
.card svg.CAL {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.laa .st6,
.card svg.LAA .st6,
.card svg.ana .st6,
.card svg.ANA .st6,
.card svg.cal .st6,
.card svg.CAL .st6 {
  fill: #dee0d3;
}
.card svg.laa .st7,
.card svg.LAA .st7,
.card svg.ana .st7,
.card svg.ANA .st7,
.card svg.cal .st7,
.card svg.CAL .st7 {
  fill: #ba0021;
}
.card svg.laa .st8,
.card svg.LAA .st8,
.card svg.ana .st8,
.card svg.ANA .st8,
.card svg.cal .st8,
.card svg.CAL .st8 {
  fill: #003263;
}
.card svg.laa .st9,
.card svg.LAA .st9,
.card svg.ana .st9,
.card svg.ANA .st9,
.card svg.cal .st9,
.card svg.CAL .st9 {
  fill: #c4ced4;
}
.card svg.sea,
.card svg.SEA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.sea .st6,
.card svg.SEA .st6 {
  fill: #dee0d3;
}
.card svg.sea .st7,
.card svg.SEA .st7 {
  fill: #0c2c56;
}
.card svg.sea .st8,
.card svg.SEA .st8 {
  fill: #005c5c;
}
.card svg.sea .st9,
.card svg.SEA .st9 {
  fill: #c4ced4;
}
.card svg.tex,
.card svg.TEX {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.tex .st6,
.card svg.TEX .st6 {
  fill: #dee0d3;
}
.card svg.tex .st7,
.card svg.TEX .st7 {
  fill: #003278;
}
.card svg.tex .st8,
.card svg.TEX .st8 {
  fill: white;
}
.card svg.tex .st9,
.card svg.TEX .st9 {
  fill: #c0111f;
}
.card svg.oak,
.card svg.OAK {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.oak .st6,
.card svg.OAK .st6 {
  fill: #dee0d3;
}
.card svg.oak .st7,
.card svg.OAK .st7 {
  fill: #003831;
}
.card svg.oak .st8,
.card svg.OAK .st8 {
  fill: white;
}
.card svg.oak .st9,
.card svg.OAK .st9 {
  fill: #efb21e;
}
.card svg.hou,
.card svg.HOU {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.hou .st6,
.card svg.HOU .st6 {
  fill: #dee0d3;
}
.card svg.hou .st7,
.card svg.HOU .st7 {
  fill: #002d62;
}
.card svg.hou .st8,
.card svg.HOU .st8 {
  fill: #f4911e;
}
.card svg.hou .st9,
.card svg.HOU .st9 {
  fill: #eb6e1f;
}
.card svg.pha,
.card svg.PHA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.pha .st6,
.card svg.PHA .st6 {
  fill: #dee0d3;
}
.card svg.pha .st7,
.card svg.PHA .st7 {
  fill: #495563;
}
.card svg.pha .st8,
.card svg.PHA .st8 {
  fill: #cfd2c7;
}
.card svg.pha .st9,
.card svg.PHA .st9 {
  fill: #bdbdb1;
}
.card svg.cha,
.card svg.CHA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.cha .st6,
.card svg.CHA .st6 {
  fill: #dee0d3;
}
.card svg.cha .st7,
.card svg.CHA .st7 {
  fill: black;
}
.card svg.cha .st8,
.card svg.CHA .st8 {
  fill: white;
}
.card svg.cha .st9,
.card svg.CHA .st9 {
  fill: #c4ced4;
}
.card svg.cle,
.card svg.CLE {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.cle .st6,
.card svg.CLE .st6 {
  fill: #dee0d3;
}
.card svg.cle .st7,
.card svg.CLE .st7 {
  fill: #e31937;
}
.card svg.cle .st8,
.card svg.CLE .st8 {
  fill: white;
}
.card svg.cle .st9,
.card svg.CLE .st9 {
  fill: #1a2e5a;
}
.card svg.min,
.card svg.MIN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.min .st6,
.card svg.MIN .st6 {
  fill: #dee0d3;
}
.card svg.min .st7,
.card svg.MIN .st7 {
  fill: #002b5c;
}
.card svg.min .st8,
.card svg.MIN .st8 {
  fill: #cfac7a;
}
.card svg.min .st9,
.card svg.MIN .st9 {
  fill: #d31145;
}
.card svg.det,
.card svg.DET {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.det .st6,
.card svg.DET .st6 {
  fill: #dee0d3;
}
.card svg.det .st7,
.card svg.DET .st7 {
  fill: #182d55;
}
.card svg.det .st8,
.card svg.DET .st8 {
  fill: white;
}
.card svg.det .st9,
.card svg.DET .st9 {
  fill: #f26722;
}
.card svg.kca,
.card svg.KCA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.kca .st6,
.card svg.KCA .st6 {
  fill: #dee0d3;
}
.card svg.kca .st7,
.card svg.KCA .st7 {
  fill: #174885;
}
.card svg.kca .st8,
.card svg.KCA .st8 {
  fill: white;
}
.card svg.kca .st9,
.card svg.KCA .st9 {
  fill: #7bb2dd;
}
.card svg.sla,
.card svg.SLA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.sla .st6,
.card svg.SLA .st6 {
  fill: #dee0d3;
}
.card svg.sla .st7,
.card svg.SLA .st7 {
  fill: #5c2a2e;
}
.card svg.sla .st8,
.card svg.SLA .st8 {
  fill: #c1b2b5;
}
.card svg.sla .st9,
.card svg.SLA .st9 {
  fill: #e34811;
}
.card svg.tba,
.card svg.TBA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.tba .st6,
.card svg.TBA .st6 {
  fill: #dee0d3;
}
.card svg.tba .st7,
.card svg.TBA .st7 {
  fill: #092c5c;
}
.card svg.tba .st8,
.card svg.TBA .st8 {
  fill: #f5d130;
}
.card svg.tba .st9,
.card svg.TBA .st9 {
  fill: #8fbce6;
}
.card svg.nya,
.card svg.NYA {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.nya .st6,
.card svg.NYA .st6 {
  fill: #dee0d3;
}
.card svg.nya .st7,
.card svg.NYA .st7 {
  fill: #132448;
}
.card svg.nya .st8,
.card svg.NYA .st8 {
  fill: white;
}
.card svg.nya .st9,
.card svg.NYA .st9 {
  fill: white;
}
.card svg.bos,
.card svg.BOS {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.bos .st6,
.card svg.BOS .st6 {
  fill: #dee0d3;
}
.card svg.bos .st7,
.card svg.BOS .st7 {
  fill: #bd3039;
}
.card svg.bos .st8,
.card svg.BOS .st8 {
  fill: white;
}
.card svg.bos .st9,
.card svg.BOS .st9 {
  fill: #192c55;
}
.card svg.bal,
.card svg.BAL,
.card svg.bln,
.card svg.BLN {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.bal .st6,
.card svg.BAL .st6,
.card svg.bln .st6,
.card svg.BLN .st6 {
  fill: #dee0d3;
}
.card svg.bal .st7,
.card svg.BAL .st7,
.card svg.bln .st7,
.card svg.BLN .st7 {
  fill: black;
}
.card svg.bal .st8,
.card svg.BAL .st8,
.card svg.bln .st8,
.card svg.BLN .st8 {
  fill: white;
}
.card svg.bal .st9,
.card svg.BAL .st9,
.card svg.bln .st9,
.card svg.BLN .st9 {
  fill: #df4601;
}
.card svg.tor,
.card svg.TOR {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.tor .st6,
.card svg.TOR .st6 {
  fill: #dee0d3;
}
.card svg.tor .st7,
.card svg.TOR .st7 {
  fill: #134a8e;
}
.card svg.tor .st8,
.card svg.TOR .st8 {
  fill: #1d2d5c;
}
.card svg.tor .st9,
.card svg.TOR .st9 {
  fill: white;
}
.card svg.ls3,
.card svg.LS3 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.ls3 .st6,
.card svg.LS3 .st6 {
  fill: #dee0d3;
}
.card svg.ls3 .st7,
.card svg.LS3 .st7 {
  fill: #dc1422;
}
.card svg.ls3 .st8,
.card svg.LS3 .st8 {
  fill: #274a75;
}
.card svg.ls3 .st9,
.card svg.LS3 .st9 {
  fill: #e7dece;
}
.card svg.kc1,
.card svg.KC1 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.kc1 .st6,
.card svg.KC1 .st6 {
  fill: #dee0d3;
}
.card svg.kc1 .st7,
.card svg.KC1 .st7 {
  fill: #046941;
}
.card svg.kc1 .st8,
.card svg.KC1 .st8 {
  fill: #fefbe8;
}
.card svg.kc1 .st9,
.card svg.KC1 .st9 {
  fill: #ffca64;
}
.card svg.cl4,
.card svg.CL4 {
  /* skin color and shirt main color: TODO: TEAM */
  /* hat main color and collar accent/ shirt piping TODO: TEAM */
  /* undershirt TODO: TEAM COLOR */
  /*hat brim and hat top button TODO: TEAM COLOR */
}
.card svg.cl4 .st6,
.card svg.CL4 .st6 {
  fill: #dee0d3;
}
.card svg.cl4 .st7,
.card svg.CL4 .st7 {
  fill: #2f3046;
}
.card svg.cl4 .st8,
.card svg.CL4 .st8 {
  fill: white;
}
.card svg.cl4 .st9,
.card svg.CL4 .st9 {
  fill: #bbbdbd;
}
.team-list-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.team-list-wrapper .team-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  /* Ensure cards align to the top, not stretch to match height */
}
.team-list-wrapper .team-card {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 300px;
  max-width: 300px;
  /* Set a max-width to prevent stretching */
  flex: 1 1 300px;
  /* Allow flexibility but enforce the max width */
  align-self: flex-start;
  /* Ensure the card stays at the top, even if it grows */
}
.team-list-wrapper .team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.team-list-wrapper .team-card .team-header {
  background-color: #393d3f;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.team-list-wrapper .team-card .team-lineup {
  padding: 0;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: none;
  color: #393d3f;
}
.team-list-wrapper .team-card .team-lineup.expanded {
  display: block;
  padding: 10px 15px;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row {
  margin: 5px 0;
  font-size: 14px;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row .lineup-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row .lineup-info .position,
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row .lineup-info .batting-order {
  font-weight: bold;
  margin-right: 10px;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row .square {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row a {
  text-decoration: none;
  color: #007bff;
}
.team-list-wrapper .team-card .team-lineup.expanded .lineup-row a:hover {
  text-decoration: underline;
}
.navbar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.navbar-container .hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin: 10px 0;
  color: #b1ffd5;
}
.navbar-container .navbar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.navbar-container .navbar .regular-nav,
.navbar-container .navbar .admin-nav {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.navbar-container .navbar .regular-nav span,
.navbar-container .navbar .admin-nav span {
  display: inline-block;
  align-self: center;
}
.navbar-container .navbar .regular-nav .navbar-link,
.navbar-container .navbar .admin-nav .navbar-link {
  color: #b1ffd5;
  cursor: pointer;
}
.navbar-container .navbar .admin-nav {
  margin-top: 10px;
  /* Separate the admin navbar from the regular navbar */
}
@media (max-width: 768px) {
  .navbar-container .hamburger {
    display: block;
  }
  .navbar-container .navbar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #44494b;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-container .navbar.open {
    display: flex;
    max-height: 1000px;
    opacity: 1;
  }
  .navbar-container .regular-nav,
  .navbar-container .admin-nav {
    flex-direction: column;
    width: 100%;
  }
  .navbar-container .regular-nav span,
  .navbar-container .admin-nav span {
    width: 100%;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #fce7b6;
    display: block;
  }
  .navbar-container .regular-nav span a,
  .navbar-container .admin-nav span a {
    display: block;
    width: 100%;
    height: 100%;
    color: #b1ffd5;
  }
  .navbar-container .regular-nav .navbar-link,
  .navbar-container .admin-nav .navbar-link {
    font-size: 18px;
  }
}
.campaign .rewards {
  border: 1px grey solid;
  padding: 3px;
}
.campaign .rewards .deleteButton {
  font-size: 8px;
  color: grey;
}
.campaign .container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.campaign .container .teamContainter,
.campaign .container .rewardContainer {
  display: inline-block;
  padding: 0 20px 0 0;
}
.coin {
  background: #ffde00;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border-width: 3px;
  border-color: darkgoldenrod;
  border-style: solid;
  box-shadow: 0 0 10px 10px #ffe600;
}
.coin .coin_text {
  padding-top: 6px;
  font-weight: 800;
  color: darkgoldenrod;
  font-size: larger;
}
footer {
  text-align: center;
  padding: 3px;
  background: #393d3f;
  color: #0b5633;
  width: 100%;
}
.play-games-page {
  margin-top: 10px;
}
.play-games-page .game-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
}
.play-games-page .game-info .scoreboard {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.play-games-page .lineups {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  flex-wrap: nowrap;
}
.play-games-page .lineups .lineup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  min-width: 0;
}
.play-games-page .lineups .lineup h3 {
  margin-bottom: 10px;
}
.play-games-page .lineups .lineup .card-container {
  width: 100%;
  height: 100%;
  max-width: 300px;
  aspect-ratio: 3 / 4;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  perspective: 1000px;
}
.play-games-page .lineups .lineup .card-container .c {
  position: relative;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}
.play-games-page .lineups .lineup .card-container .card-front,
.play-games-page .lineups .lineup .card-container .card-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 3 / 4;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}
.play-games-page .lineups .lineup .card-container .card-back {
  transform: rotateY(180deg);
}
.play-games-page .lineups .lineup .card-container.flipped .card-front {
  transform: rotateY(180deg);
}
.play-games-page .lineups .lineup .card-container.flipped .card-back {
  transform: rotateY(0deg);
}
.play-games-page .lineups .lineup:last-child {
  margin-right: 0;
}
.play-games-page .events-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}
.play-games-page .events-container .event-card {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  align-items: flex-start;
  min-width: 0;
  width: 300px;
}
.play-games-page .events-container .dice-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.play-games-page .events-container .dice-container .dice-flex {
  display: flex;
  align-items: center;
}
.play-games-page .events-container .dice-container .dice-flex .flip-board {
  margin-left: 10px;
}
.play-games-page .events-container .dice-container .dice-div {
  width: 100%;
}
.play-games-page .events-container .dice-container .dice {
  font-weight: bold;
  margin-top: 10px;
}
.play-games-page .events-container .current-status,
.play-games-page .events-container .current-event {
  margin-bottom: 5px;
}
.play-games-page .participants-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.play-games-page .scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border: 2px solid #000;
  border-radius: 8px;
  padding: 10px;
}
.play-games-page .scoreboard .scoreboard-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
}
.play-games-page .scoreboard .inning-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}
.play-games-page .scoreboard .inning {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32px;
  padding: 2px;
}
.play-games-page .scoreboard .inning-number {
  margin-bottom: 2px;
}
.play-games-page .scoreboard .inning-score {
  margin-top: 2px;
}
.play-games-page .scoreboard .scoreboard-visitor,
.play-games-page .scoreboard .scoreboard-home {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 5px 0;
  border-top: 1px solid #ccc;
}
.play-games-page .scoreboard .team {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-games-page .scoreboard .scoreboard-visitor .team-small,
.play-games-page .scoreboard .scoreboard-home .team-small {
  display: none;
}
.play-games-page .scoreboard .scoreboard-visitor span,
.play-games-page .scoreboard .scoreboard-home span {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .play-games-page .lineups,
  .play-games-page .events-container {
    flex-wrap: wrap;
  }
  .play-games-page .lineup,
  .play-games-page .event-card,
  .play-games-page .dice-container {
    flex: 1 1 auto;
    min-width: 0;
  }
  .play-games-page .lineup .card-container {
    max-width: 100%;
    aspect-ratio: 3 / 4;
  }
  .play-games-page .team-name {
    display: none;
  }
  .play-games-page h3 {
    margin-top: 2px;
  }
  .play-games-page .scoreboard .inning {
    width: 20px;
    padding: 0 2px;
  }
  .play-games-page .scoreboard .scoreboard-visitor .team,
  .play-games-page .scoreboard .scoreboard-home .team {
    display: none;
  }
  .play-games-page .scoreboard .scoreboard-visitor .team-small,
  .play-games-page .scoreboard .scoreboard-home .team-small {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.card-detail-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
  /* Allows wrapping on smaller screens */
  /* Media query for smaller screens (mobile) */
}
.card-detail-page .card-info,
.card-detail-page .card-stats,
.card-detail-page .card-inventory-stats {
  flex: 1;
  max-width: 400px;
  padding: 10px;
  background-color: #393d3f;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  /* Add margin to prevent overlap when stacking */
}
.card-detail-page p {
  margin: 1px;
}
.card-detail-page .card-dice p:nth-child(even) {
  color: #000;
}
.card-detail-page .card-dice p {
  padding: 1px;
}
@media (max-width: 768px) {
  .card-detail-page {
    flex-direction: column;
    /* Stack elements vertically */
    gap: 10px;
    /* Reduce gap between elements */
  }
  .card-detail-page .card-info,
  .card-detail-page .card-stats,
  .card-detail-page .card-inventory-stats {
    max-width: 100%;
    /* Make each container take full width */
  }
}
.card-container {
  perspective: 1000px;
  width: 300px;
  height: 400px;
  position: relative;
  cursor: pointer;
  /* Reduce card size on smaller screens while maintaining aspect ratio */
}
.card-container .c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: transform, opacity;
  backface-visibility: hidden;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card-container .card-front,
.card-container .card-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #dedee0;
  color: #393d3f;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .card-container {
    aspect-ratio: 3 / 4;
  }
}
.card-container .card-front-content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
}
.card-container .card-front-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /* Darken the background slightly */
  z-index: 0;
}
.card-container .card-front-content .card-padding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0;
}
.card-container .card-front-content .card-padding .card-top {
  align-self: flex-start;
  padding: 10px 10px;
}
.card-container .card-front-content .card-padding .card-bottom {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.card-container .card-front-content p {
  margin-bottom: 8px;
  padding: 0px;
}
.card-container h2,
.card-container p {
  position: relative;
  z-index: 1;
  margin-top: 0px;
  margin-bottom: 4px;
}
@media (max-width: 768px) {
  .card-container h2 {
    font-size: 4vw;
  }
}
.card-container .card-name-length-2 {
  font-size: 0.9em;
}
.card-container .card-name-length-3 {
  font-size: 0.8em;
}
.card-container .card-name-length-4 {
  font-size: 0.7em;
}
.card-container .card-name-length-5 {
  font-size: 0.6em;
}
.card-container .card-back {
  transform: rotateY(180deg);
  top: 0;
  left: 0;
}
.card-container .card-dice {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}
.card-container .b-dice,
.card-container .p-dice,
.card-container .x-dice {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 40%;
  padding: 0 10px;
}
.card-container .b-dice p,
.card-container .p-dice p,
.card-container .x-dice p {
  margin: 2px 0;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .card-container .b-dice p,
  .card-container .p-dice p,
  .card-container .x-dice p {
    margin: 0;
    white-space: nowrap;
  }
}
.card-container .p-dice {
  font-size: 0.9em;
}
.card-container .square {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 10%;
  padding-left: 4px;
}
@media (max-width: 768px) {
  .card-container .square {
    width: 25px;
    height: 25px;
    padding-left: 3px;
    padding-top: 2px;
  }
}
.card-container .highlight {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1);
}
.square {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10%;
}
.card-variants-page {
  display: flex;
  /* Display images in a row */
  flex-wrap: wrap;
  /* Allow wrapping if there are many variants */
}
.card-variants-page .variant-container {
  display: inline-block;
  /* Make container inline-block to wrap the image */
  margin: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  /* Default border */
  transition: border 0.3s;
  /* Smooth transition for border when active */
}
.card-variants-page .variant-container.active {
  border: 2px solid blue;
  /* Highlight active variant */
}
.card-variants-page .variant-container .variant-image {
  width: 100%;
  /* Reduce the size of the image */
  height: auto;
  /* Maintain the aspect ratio */
  display: block;
  /* Ensure the image is block-level to avoid inline spacing issues */
  max-width: 100px;
  /* Optionally limit the maximum width */
}
.dice-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dice-animation .dice-roll {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}
.dice-animation .dice-label {
  margin-right: 0.5rem;
}
.dice-animation .dice-face {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
  background-color: #fce7b6;
  color: #393d3f;
}
.dice-animation .dice-face.xbh {
  color: #0b5633;
  background-color: #4ca971;
}
.dice-animation .dice-face.pitching {
  background-color: #fff;
  color: #0b5633;
}
.admin-dice-roll-page .admin-dice-roll-page {
  margin: 20px;
}
.admin-dice-roll-page .selects-and-cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  /* Add some spacing before the dice roller */
}
.admin-dice-roll-page .card-selection {
  flex: 1;
  margin-right: 20px;
}
.admin-dice-roll-page .cards-display {
  display: flex;
  justify-content: space-around;
  flex: 1;
}
.admin-dice-roll-page .cards-display > * {
  margin-left: 10px;
}
.admin-dice-roll-page .dice-roller-container {
  margin-top: 20px;
}
.dice-roller .roll-result-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* Optional: Add space between the two result sections */
}
.dice-roller .roll-results {
  flex: 1;
  /* Make both result sections take up equal space */
  border: 1px solid #ccc;
  /* Optional: Add some styling for better visualization */
  padding: 10px;
  /* Optional: Add padding for aesthetics */
}
.dice-roller .pre-roll-results {
  /* Additional styling for pre-roll results if needed */
}
.dice-roller .post-roll-results {
  /* Additional styling for post-roll results if needed */
}
.buy-packs-page .packs-grid,
.user-packs-page .packs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
}
.buy-packs-page .header-info,
.user-packs-page .header-info {
  display: flex;
  justify-content: space-between;
  /* Ensures items are on opposite sides */
  align-items: center;
  /* Vertically aligns the items */
  margin-bottom: 20px;
  /* Adds some space below the header */
}
.buy-packs-page .header-item,
.user-packs-page .header-item {
  font-weight: bold;
  font-size: 2rem;
  /* Equivalent to an h2 size */
  margin-top: 10px;
}
@media (max-width: 768px) {
  .buy-packs-page .header-item,
  .user-packs-page .header-item {
    font-size: 1.5rem;
    /* Equivalent to an h2 size */
  }
}
.buy-packs-page .pack-item,
.user-packs-page .pack-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}
.buy-packs-page .pack-item .pack-image,
.user-packs-page .pack-item .pack-image {
  max-width: 150px;
  /* Set a maximum width */
  max-height: 200px;
  /* Set a maximum height */
  width: 100%;
  /* Ensure the image scales properly */
  height: auto;
  /* Maintain the aspect ratio */
  margin-bottom: 10px;
}
.buy-packs-page .pack-item .pack-details,
.user-packs-page .pack-item .pack-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buy-packs-page .pack-item .pack-details .pack-title,
.user-packs-page .pack-item .pack-details .pack-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.buy-packs-page .pack-item .pack-details .pack-price,
.user-packs-page .pack-item .pack-details .pack-price {
  margin-bottom: 10px;
}
.buy-packs-page .pack-item .pack-details .pack-buttons,
.user-packs-page .pack-item .pack-details .pack-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.buy-packs-page .pack-item .pack-details .pack-buttons button,
.user-packs-page .pack-item .pack-details .pack-buttons button {
  padding: 5px 10px;
  background-color: #f15025;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.buy-packs-page .pack-item .pack-details .pack-buttons button:hover,
.user-packs-page .pack-item .pack-details .pack-buttons button:hover {
  background-color: #0056b3;
}
.leader-container .header {
  margin-bottom: 20px;
  text-align: center;
}
.leader-container .leaderboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}
.leader-container .stat-category {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #ddd;
}
.leader-container .stat-category h3 {
  margin-bottom: 10px;
  color: #393d3f;
}
.leader-container .stat-category ul {
  list-style-type: none;
  padding: 0;
  font-size: small;
}
.leader-container .stat-category ul li {
  border-radius: 5px;
  color: #393d3f;
}
.leader-container .stat-category ul a {
  margin-right: 5px;
  text-decoration: none;
  color: #393d3f;
}
.leader-container .stat-category ul a:hover {
  font-weight: bold;
}
.leader-container .stat-category ul .square {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.admin-dice-roll-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.admin-dice-roll-page .card-selection,
.admin-dice-roll-page .num-rolls {
  margin-bottom: 10px;
}
.admin-dice-roll-page .card-selection label,
.admin-dice-roll-page .num-rolls label {
  margin-right: 10px;
}
.admin-dice-roll-page .card-selection select,
.admin-dice-roll-page .num-rolls select,
.admin-dice-roll-page .card-selection input,
.admin-dice-roll-page .num-rolls input {
  padding: 5px;
}
.admin-dice-roll-page .roll-results {
  margin-top: 20px;
}
.admin-dice-roll-page .roll-results ul {
  list-style-type: none;
  padding: 0;
}
.admin-dice-roll-page .roll-results .batting-stats {
  margin-top: 20px;
}
.flip-board {
  perspective: 1000px;
  max-width: 300px;
}
.flip-board .flip-board-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-board.flipped .flip-board-inner {
  transform: rotateX(180deg);
}
.flip-board .flip-board-front,
.flip-board .flip-board-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  border: 1px solid #000;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .flip-board .flip-board-front,
  .flip-board .flip-board-back {
    font-size: 1.2em;
  }
}
.flip-board .flip-board-front {
  background-color: #393d3f;
  /* Dark background for front */
}
.flip-board .flip-board-back {
  background-color: #506f73;
  color: whitesmoke;
  font-weight: bold;
  transform: rotateX(180deg);
}
.flip-board .flip-result {
  font-family: 'New Amsterdam', sans-serif;
  font-size: 26px;
}
.flip-board .flip-text-small {
  display: none;
}
.flip-board .filp-text {
  display: flex;
}
@media (max-width: 768px) {
  .flip-board .flip-text {
    display: none;
  }
  .flip-board .flip-text-small {
    display: flex;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-overlay .modal-content {
  background-color: #696969;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  position: relative;
  z-index: 1001;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .modal-overlay .modal-content {
    width: 95%;
    max-height: 95vh;
  }
}
.modal-overlay .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}
.modal-overlay .close-button:hover {
  color: #000;
}
.modal-overlay .close-button:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
html,
body,
form,
fieldset,
table,
tr,
td,
p {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}
html,
body {
  height: 100%;
  margin: 10px;
}
@media (max-width: 768px) {
  html,
  body {
    margin: 2px;
  }
}
body,
main {
  width: 100%;
  color: #fbfef9;
}
main {
  flex: 1;
}
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 900px;
  margin: 0 auto;
  /* Centers the content horizontally */
}
.fake_link,
a {
  color: #b1ffd5;
  text-decoration: none;
}
.fake_link:hover,
a:hover {
  font-weight: bold;
  cursor: pointer;
}
.fake_link:visited,
a:visited {
  color: #b1ffd5;
}
body {
  background-image: url('../img/grey_vintage_hex.png');
  background-size: auto;
  /* This will scale the background image to cover the entire page */
  background-repeat: repeat;
  /* This will make the background image repeat */
  background-position: center;
  /* This will center the background image */
  background-color: #393d3f;
}
th {
  cursor: pointer;
}
th.ascending::after {
  content: ' ▲';
}
th.descending::after {
  content: ' ▼';
}
.bold {
  font-weight: bold;
}
.error {
  font-weight: bold;
  color: red;
  border: 2px solid red;
  padding: 5px;
}
button,
.button {
  background-color: #f15025;
  border: none;
  border-radius: 5px;
  color: #fbfef9;
  padding: 5px 8px;
  margin: 3px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
button a,
.button a {
  color: #fbfef9;
  font-weight: bold;
  text-decoration: none;
}
button a:visited,
.button a:visited {
  color: #fbfef9;
}
.less-action {
  background-color: #43c167;
}
.inactive {
  background-color: #788084;
  color: #dedee0;
}
.padding20 {
  padding: 20px;
}
.padding2010 {
  padding: 20px 10px;
}
.width100 {
  width: 100%;
}
.boring_button {
  background-color: #fbfef9;
  color: #393d3f;
}
.index {
  color: grey;
}
.hittingSheet,
.pitchingSheet,
.lineupSheet,
.hittingXBHSheet,
.rollTestTable,
.matchUpPicker,
.chart {
  border: 2px solid grey;
  padding: 10px;
  margin: 10px;
}
.floatChildren div {
  display: inline-block;
}
.addCard {
  padding: 5px;
  margin: 5px;
  border: 1px solid black;
}
.yearAvg {
  width: 100%;
}
.matchUpPicker {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
}
.playerContainer .playerTable {
  padding: 5px;
  margin: 5px;
  border: 2px solid Grey;
  width: 100%;
}
.playerContainer .playerTable td {
  border: 1px solid LightGrey;
}
.playerContainer .playerTable tr.few td {
  background-color: #44494b;
}
.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.seriesContainer .inactive {
  color: lightGrey;
}
.seriesContainer .inactive a {
  color: lightGrey;
}
.material-icons.md-18,
.material-icons-outlined.md-18 {
  font-size: 18px;
}
.leaderPitching .pitchingbatting {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
}
.leaderPitching .pitching {
  font-size: 1.5em;
  font-weight: bold;
  color: #ef5a34;
  font-family: 'Manrope', sans-serif;
}
.leaderPitching .batting {
  margin-left: 0.5em;
}
.leaderBatting .pitchingbatting {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
}
.leaderBatting .pitching {
  margin-left: 0.5em;
}
.leaderBatting .batting {
  font-size: 1.5em;
  font-weight: bold;
  color: #ef5a34;
  font-family: 'Manrope', sans-serif;
}
.completed {
  background-color: green;
  color: white;
  padding: 3px;
  border-radius: 3px;
  border: green 1px solid;
  margin: 0 5px;
}
.table-striped tbody tr:nth-child(even) {
  background-color: #44494b;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}
.pagination .page-item {
  margin: 0 5px;
}
.pagination .page-item.active .page-link {
  font-weight: bold;
}
.pagination .page-item .page-link {
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
}
.accordion-content,
.accordion-body {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.accordion-header {
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  /* Ensure spacing is consistent */
}
.accordion-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.nowrap {
  white-space: nowrap;
}
